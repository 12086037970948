<script setup lang="ts">
definePageMeta({
  auth: false,
  layout: "empty",
});

onMounted(() => {
  navigateTo("/api/auth/login", {
    external: true,
  });
});
</script>

<template>
  <div
    class="bg-slate-100 h-screen flex items-center flex-col justify-center space-y-4"
  >
    <img src="~assets/images/if-logo.svg" class="w-14" />
  </div>
</template>
